import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "./components/Header";
import Loading from "./components/Loading";
import "../css/style.css"

const Home = () => {
    const [orderId, setOrderId] = useState("");
    const [eventId, setEventId] = useState("");
    const [ticketTypeId, setTicketTypeId] = useState("");
    const [selectedTicketTypes] = useState([]);
    const [promoCode, setPromoCode] = useState("");
    const [promoError, setPromoError] = useState("");
    const [loading, setLoading] = useState(true);

    const { code } = useParams();

    const [user, setUser] = useState({
        promocode: code
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            if (sessionStorage.getItem("promotionCode")) {
                sessionStorage.setItem("promotionCode", "");
            }

            if (sessionStorage.getItem("promotionSuccess")) {
                sessionStorage.setItem("promotionSuccess", false);
            }

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            startOrder(res.data.posId, res.data.eventId);
            setEventId(res.data.eventId);
            setTicketTypeId(res.data.ticketTypeId);
        });
    };

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = (posId, eventId, ticketTypeId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("OrderId", res.data.id);

                setOrderId(res.data.id);
                setLoading(true);

                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => console.error(error.response.data));
    };

    const applyPromotionCode = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${user.promocode}`)
            .then((res) => {
                setPromoError("");
                sessionStorage.setItem("promotionCode", user.promocode);
                addTicketToBasket();
            })
            .catch((error) => {
                setPromoError(
                    "Dieser Gutschein Code kann nicht auf ihre aktuelle Bestellung angewendet werden."
                );
            });
    };

    const onSubmit = async () => {
        applyPromotionCode();
    };

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = [{ ticketTypeId: ticketTypeId, quantity: 1, promotionCode: user.promocode }];

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                navigate(`/${language}/shipping`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // validating input fields
    let validation =
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.promocode);

    return (
        <div>
            {/* loop the ticket types */}
            {loading ? (
                <Loading
                    alignment="center"
                    color="#d3d3d3"
                    bgColor="#fff"
                    position="fixed"
                    top="50%"
                    left="50%"
                />
            ) : (
                <>
                    {/* header */}

                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Header language={language} />
                            </div>
                        </div>
                    </div>

                    {resources.translation && (
                        <div className="container wrapper">
                            <div className="ms-3 me-3 mt-3 mb-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 dangerouslySetInnerHTML={{ __html: resources.translation.HomeTitle }}></h4>
                                    </div>
                                </div>

                                {/* promocode */}

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <p className="font-size-18px"><b>{resources.translation.HomeSubTitle}</b></p>
                                        <p dangerouslySetInnerHTML={{ __html: resources.translation.HomeDescription }}></p>
                                    </div>
                                    <div className="col-md-8 mt-3">
                                        <input
                                            type="text"
                                            name="promocode"
                                            placeholder={resources.translation.HomePromocodePlaceholder}
                                            className="form-control customTextbox font-size-18px p-2 text-center"
                                            value={user.promocode}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                    <div className="offset-md-1 col-md-3 mt-3">
                                        <button
                                            className="custom-button p-2"
                                            onClick={onSubmit}
                                            disabled={!validation}
                                        >
                                            {resources.translation.HomeNext}
                                        </button>
                                    </div>
                                </div>

                                {promoError && promoError != "" &&
                                    <div className="row">
                                        <div className="col-md-8 red">
                                            <p>{promoError}</p>
                                        </div>
                                    </div>
                                }

                            </div>
                            <p className="mt-5 text-center">
                                {resources.translation && (
                                    <small style={{ color: '#ffa834' }}>powered by <a style={{ color: '#ffa834' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                )}
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Home;
