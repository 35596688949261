import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "./components/Header";
import Loading from "./components/Loading";
import "../css/style.css"

const Step10_Shipping = () => {
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [promocode] = useState(sessionStorage.getItem("promotionCode"));
    const [loading, setLoading] = useState(false);
    const [token] = useState(sessionStorage.getItem("token"));


    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        title: "",
        function: "",
        company: "",
        street: "",
        place: "",
        city: "",
        country: "176",
        mobile: "",
        email: "",
        programcoffee: "",
        programfestakt: "",
        programapero: "",
        programtribune: "",
        foodtype: "",
        promocode: promocode
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    useEffect(() => {
        if (token === "") navigate(`/${language}/sessiontimeout`);

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestResources();

    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const onSubmit = async () => {
        addAddressToBasket();
    };

    const addAddressToBasket = () => {
        let addressBody = {
            salutation: user.salutation,
            firstname: user.firstname,
            name: user.lastname,
            title: user.title,
            function: user.function,
            company: user.company,
            street: user.street,
            postCode: user.place,
            city: user.city,
            countryId: user.country,
            mobile: user.mobile,
            email: user.email,
            code1: user.programcoffee,
            code2: user.programfestakt,
            code3: user.programapero,
            code4: user.programtribune,
            code5: user.foodtype,
            code10: user.promocode
        };
        axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then((res) => {
                changePaymentTypeToFree();
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(() => {
                navigate(`/${language}/confirmation`);
            });
    };

    const onCheckedChange = (e) => {

        /* salutations */
        if (e.target.value.includes(resources.translation._Mr)) {
            setUser({ ...user, ["salutation"]: resources.translation._Mr });
        }

        if (e.target.value.includes(resources.translation._Mrs)) {
            setUser({ ...user, ["salutation"]: resources.translation._Mrs });
        }

        /* programs */
        if (e.target.value.includes(resources.translation.ShippingProgramCoffee)) {
            if (e.target.checked) {
                setUser({ ...user, ["programcoffee"]: resources.translation.ShippingProgramCoffee });
            } else {
                setUser({ ...user, ["programcoffee"]: "" });
            }
        }

        if (e.target.value.includes(resources.translation.ShippingProgramFestakt)) {
            if (e.target.checked) {
                setUser({ ...user, ["programfestakt"]: resources.translation.ShippingProgramFestakt });
            } else {
                setUser({ ...user, ["programfestakt"]: "" });
            }
        }

        if (e.target.value.includes(resources.translation.ShippingProgramApero)) {
            if (e.target.checked) {
                setUser({ ...user, ["programapero"]: resources.translation.ShippingProgramApero });
            } else {
                setUser({ ...user, ["programapero"]: "" });
            }
        }

        if (e.target.value.includes(resources.translation.ShippingProgramTribune)) {
            if (e.target.checked) {
                setUser({ ...user, ["programtribune"]: resources.translation.ShippingProgramTribune });
            } else {
                setUser({ ...user, ["programtribune"]: "" });
            }
        }

        /* food */
        if (e.target.value.includes(resources.translation.ShippingFoodTypeMeat)) {
            setUser({ ...user, ["foodtype"]: resources.translation.ShippingFoodTypeMeat });
        }

        if (e.target.value.includes(resources.translation.ShippingFoodTypeVegeterian)) {
            setUser({ ...user, ["foodtype"]: resources.translation.ShippingFoodTypeVegeterian });
        }
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // validating input fields
    let validation =
        /^([a-z A-Zöéàäèü]{1,70})$/.test(user.salutation) &&
        /^([a-z A-Zöéàäèü - ]{1,70})$/.test(user.firstname) &&
        /^([a-z A-Zöéàäèü - ]{1,70})$/.test(user.lastname) &&
        /^([a-z A-Zöéàäèü. 0-9 - ]{1,70})$/.test(user.title) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.function) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.company) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.street) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.place) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.city) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.mobile) &&
        /^([a-z A-Zöéàäèü]{1,70})$/.test(user.foodtype) &&
        /^\S+@\S+\.\S+$/.test(user.email);

    console.log(user);

    return (
        <div>
            {/* loop the ticket types */}
            {loading ? (
                <Loading
                    alignment="center"
                    color="#d3d3d3"
                    bgColor="#fff"
                    position="fixed"
                    top="50%"
                    left="50%"
                />
            ) : (
                <>
                    {/* header */}

                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Header language={language} />
                            </div>
                        </div>
                    </div>

                    {resources.translation && (
                        <div className="container wrapper">
                            <div className="ms-3 me-3 mt-3">

                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 dangerouslySetInnerHTML={{ __html: resources.translation.HomeTitle }}></h4>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <span className="font-size-18px dark-gray fw-bold">PERSONALIEN</span>
                                    </div>
                                </div>


                                {/* user data */}

                                <div className="mb-4">
                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Anrede:</span>
                                        </div>
                                        <div className="col-md-8 mt-3 gray">
                                            <input
                                                id="mr"
                                                type="radio"
                                                name="salutation"
                                                value={resources.translation._Mr}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="mr">Herr</label>

                                            <input
                                                id="mrs"
                                                type="radio"
                                                className="ms-4"
                                                name="salutation"
                                                value={resources.translation._Mrs}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="mrs">Frau</label>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Vorname:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="firstname"
                                                className="form-control customTextbox"
                                                value={user.firstname}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Nachname:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="lastname"
                                                className="form-control customTextbox"
                                                value={user.lastname}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Titel:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="title"
                                                className="form-control customTextbox"
                                                value={user.title}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Funktion:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="function"
                                                className="form-control customTextbox"
                                                value={user.function}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Organisation:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="company"
                                                className="form-control customTextbox"
                                                value={user.company}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Street:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="street"
                                                className="form-control customTextbox"
                                                value={user.street}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">PLZ / Stadt:</span>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-2 mt-3">
                                            <input
                                                type="text"
                                                name="place"
                                                className="form-control customTextbox"
                                                value={user.place}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="col-8 col-sm-8 col-md-6 mt-3">
                                            <input
                                                type="text"
                                                name="city"
                                                className="form-control customTextbox"
                                                value={user.city}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">Telefonnummer Mobile:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="text"
                                                name="mobile"
                                                className="form-control customTextbox"
                                                value={user.mobile}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-3 mt-3 text-md-end">
                                            <span className="dark-gray">E-Mail:</span>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control customTextbox"
                                                value={user.email}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* programs */}

                                    <div className="mt-5">
                                        <div className="row">
                                            <div className="offset-md-1 col-md-12">
                                                <span className="font-size-18px dark-gray fw-bold">SIE NEHMEN TEIL AM:</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="offset-md-3 col-md-8 gray">
                                            <input
                                                id="programcoffee"
                                                type="checkbox"
                                                name="programcoffee"
                                                value={resources.translation.ShippingProgramCoffee}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="programcoffee">{resources.translation.ShippingProgramCoffee}</label>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="offset-md-3 col-md-8 gray">
                                            <input
                                                id="programfestakt"
                                                type="checkbox"
                                                name="programfestakt"
                                                value={resources.translation.ShippingProgramFestakt}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="programfestakt">{resources.translation.ShippingProgramFestakt}</label>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="offset-md-3 col-md-8 gray">
                                            <input
                                                id="programapero"
                                                type="checkbox"
                                                name="programapero"
                                                value={resources.translation.ShippingProgramApero}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="programapero">{resources.translation.ShippingProgramApero}</label>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="offset-md-3 col-md-8 gray">
                                            <input
                                                id="programtribune"
                                                type="checkbox"
                                                name="programtribune"
                                                value={resources.translation.ShippingProgramTribune}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="programtribune">{resources.translation.ShippingProgramTribune}</label>
                                        </div>
                                    </div>

                                    {/* food */}

                                    <div className="row mt-4">
                                        <div className="offset-md-3 col-md-8">
                                            <span className="font-size-18px">Essen</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="offset-md-3 col-md-8 gray">
                                            <input
                                                id="meat"
                                                type="radio"
                                                name="foodtype"
                                                value={resources.translation.ShippingFoodTypeMeat}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="meat">{resources.translation.ShippingFoodTypeMeat}</label>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="offset-md-3 col-md-8 gray">
                                            <input
                                                id="vegeterian"
                                                type="radio"
                                                name="foodtype"
                                                value={resources.translation.ShippingFoodTypeVegeterian}
                                                onChange={(e) => onCheckedChange(e)}
                                                required
                                            />
                                            <label className="ms-2" htmlFor="vegeterian">{resources.translation.ShippingFoodTypeVegeterian}</label>
                                        </div>
                                    </div>
                                </div>

                                {/* button confirm */}

                                <div className="row mt-4">
                                    <div className="offset-md-7 col-md-4 text-end">
                                        <button
                                            className="custom-button"
                                            onClick={onSubmit}
                                            disabled={!validation}
                                        >
                                            {resources.translation.HomeNext}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <p className="mt-5 text-center">
                                {resources.translation && (
                                    <small style={{ color: '#ffa834' }}>powered by <a style={{ color: '#ffa834' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                )}
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Step10_Shipping;
